import pdfMake from "pdfmake/build/pdfmake";
import generateQRCode from "./generateQRCode";
const vfsFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = vfsFonts.pdfMake.vfs;

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
}

// const qrCodeData = "https://collect.nobuk.africa";
const qrCodeSize = 68;

export const generateReceipt = async ({
  phoneNumberBy,
  paidBy,
  amount,
  transactionCode,
  orgName,
  selection,
  setProcessing,
}) => {
  setProcessing(true);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const formattedTime = formatAMPM(currentDate);

  const maxLinesForOrgName = 2;
  const maxCharsPerLine = 20;
  const maxFontSize = 18;

  const numLines = Math.ceil(orgName?.length / maxCharsPerLine);

  const fontSize =
    numLines > 2 ? maxFontSize - (numLines - 2) * 2 : maxFontSize;

  const truncatedOrgName =
    orgName?.length > maxLinesForOrgName * maxCharsPerLine
      ? orgName.substring(0, maxLinesForOrgName * maxCharsPerLine + 16) + "..."
      : orgName;

  const qrCodeDataURL = await generateQRCode(transactionCode);

  const documentDefinition = {
    pageSize: "A6",
    pageMargins: [40, 25, 40, 30],
    content: [
      {
        text: "RECEIPT",
        style: "header",
        fontSize: 20,
        alignment: "center",
      },
      {
        width: "*",
        text: [{ text: `Date: ${formattedDate}`, style: "date" }],
      },
      {
        width: "100",
        text: [{ text: `Time: ${formattedTime}`, style: "time" }],
      },
      {
        text: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -",
        style: "line",
      },
      {
        text: " ",
        style: "space",
      },
      {
        text: truncatedOrgName,
        style: "heading",
        maxLines: maxLinesForOrgName,
      },
      {
        text: selection,
        maxLines: maxLinesForOrgName,
        style: "equal",
        color: "#bbbbbb",
        bold: true,
      },
      {
        text: " ",
        style: "space",
      },
      {
        text: "=================================",
        style: "equal",
      },
      {
        text: " ",
        style: "space",
      },
      {
        text: " ",
        style: "space",
      },
      {
        text: [
          { text: `${paidBy || "Unknown"}\n`, style: "texts" },
          { text: `${phoneNumberBy || "07********"}`, style: "texts" },
        ],
        style: "text",
      },
      {
        text: [
          { text: "MPESA CODE\n", style: "text", color: "#bbbbbb", bold: true },
          { text: `${transactionCode || "***********"}`, style: "texts" },
        ],
      },
      {
        text: [
          { text: "Paid ", style: "text" },
          { text: "KES ", style: "text", fontSize: 10, bold: true },
          {
            text: amount?.toFixed(2),
            style: "texts",
            fontSize: 26,
            bold: "true",
          },
        ],
        style: "text",
      },
      {
        text: "=================================",
        style: "equal",
      },
      {
        text: " ",
        style: "space",
      },
      {
        image: qrCodeDataURL,
        width: qrCodeSize,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      {
        text: " ",
        style: "space",
      },
    ],
    footer: {
      text: "Scan to confirm payment",
      style: "footer",
    },

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      space: {
        fontSize: 2,
      },
      heading: {
        fontSize,
        color: "#062a30",
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      date: {
        fontSize: 10,
        margin: [10, 0, 0, 10],
        alignment: "left",
      },
      time: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
        alignment: "left",
      },
      scan: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
        alignment: "center",
      },
      line: {
        fontSize: 12,
        margin: [0, 0, 0, 0],
        alignment: "center",
      },
      equal: {
        fontSize: 12,
        margin: [0, 0, 0, 0],
        alignment: "center",
      },
      text: {
        fontSize: 12,
        margin: [0, 0, 0, 5],
        alignment: "center",
      },
      texts: {
        bold: true,
        fontSize: 16,
        margin: [0, 10, 0, 10],
        alignment: "center",
      },
      thankYou: {
        fontSize: 16,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 0],
      },
      footer: {
        fontSize: 10,
        alignment: "center",
        margin: [0, 10, 0, 0],
      },
    },
  };

  const pdfDoc = pdfMake.createPdf(documentDefinition);
  pdfDoc.download(`${orgName}-receipt.pdf`);
  setProcessing(false);
};
